<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Information') "
      :subtitle="selectedEntityType ? $t( selectedEntityType.charAt(0).toUpperCase() + selectedEntityType.slice(1) ) : $t('Structure')"
      :root="selectedEntityType ? $t('Structure') : null"
      icon="mdi-account"
      :header-action="openNewInstitute"
      :header-action-text="$t('wsu.structure.faculty.add')"
      fill-height
  >

    <h1>Description will be here</h1>


  </sub-page>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "description",
  computed : {
    ...mapState('structure' , [
        'selectedEntityType' ,
        'selectedEntityId',
        'selectedEntityName',
        'returnEntityType',
        'returnEntityId',
        'returnEntityName',
    ])
  }
}
</script>

<style scoped>

</style>